import { mapGetters } from 'vuex'
import { groupBy } from 'lodash'

export default {
  computed: {
    ...mapGetters({
      infos_cadastre: 'parcelle/infos_cadastre',
      urbanismeStore: 'dossierpromoteur/getUrbanisme',
      informationsStore: 'dossierpromoteur/getinfos',
      api_zones_urbanisme_loaded: 'loading/api_zones_urbanisme_loaded',
    }),
  },
  data() {
    return {
      is_api_zone_loading: true,
      urbanisme: {
        version: 2,
        all_zones_urba: [],
        hauteur: null,
        ces: null,
        pup: null,
        abf: null,
        cpt: null,
        stationement: null,
        mixite_social: null,
        bande_principale: null,
        bande_secondaire: null,
        imple_vole: null,
        impl_limite_separative: null,
        impl_batiment: null,
        caractere_zone: null,
        show_docs: 'Oui',
        pdfs: [],
        articles_plu: [],
      },
    }
  },
  methods: {
    updateZonesUrbaine(informations, reload_map = false) {
      let informations_urbanisme = _.merge(
        {},
        this.urbanisme,
        this.urbanismeStore,
      )
      let ids_parcelle = [
        informations.current_parcelle,
        ...informations.parcelles_voisines,
      ].map((parcelle) => parcelle.properties.id)
      return this.$http
        .get('address', {
          params: {
            lon: informations.center_parcelle_principale[0],
            lat: informations.center_parcelle_principale[1],
            rayon_api_cadastre: 0,
            ids_parcelle: ids_parcelle,
            type: 'cadastre',
            details_urb: 1,
          },
        })
        .then((response) => {
          let keys_to_add = {
            hauteur: 'hauteur_des_facades',
            ces: 'coefficient_emprise_sol',
            cpt: 'coefficient_pleine_terre',
            stationement: null,
            mixite_social: null,
            bande_principale: null,
            bande_secondaire: null,
            imple_vole: null,
            impl_limite_separative: 'limites_separative',
            impl_batiment: null,
            caractere_zone: null,
            espace_vert: null,
          }

          // group by and union zones urbaine by libelle
          let new_list_zones = []
          let group_by_zone = groupBy(
            response.data.cadastre.informations[0].zone_urba_obj,
            'properties.libelle',
          )
          Object.keys(group_by_zone).forEach((key) => {
            new_list_zones.push(this.$turf.union(...group_by_zone[key]))
          })
          new_list_zones.forEach((zone) => {
            Object.keys(keys_to_add).forEach((key) => {
              zone.properties[key] = zone.properties.hasOwnProperty(key)
                ? zone.properties[key]
                : null
            })
          })
          //  update or add new zone urbaine
          new_list_zones.forEach((zone) => {
            let is_exist = false
            informations_urbanisme.all_zones_urba.forEach((item) => {
              if (zone.properties.libelle === item.properties.libelle) {
                item.geometry = zone.geometry
                is_exist = true
              }
            })
            if (!is_exist) {
              informations_urbanisme.all_zones_urba.push(zone)
            }
          })
          // remove unused zone urbaine
          informations_urbanisme.all_zones_urba =
            informations_urbanisme.all_zones_urba.filter((zone) =>
              new_list_zones.some(
                (item) => zone.properties.libelle === item.properties.libelle,
              ),
            )
          // remove duplicate zone old list zone urbaine
          informations_urbanisme.all_zones_urba = _.uniqBy(
            informations_urbanisme.all_zones_urba,
            'properties.libelle',
          )

          informations_urbanisme.all_zones_urba.forEach((zone) => {
            Object.keys(keys_to_add).forEach((key) => {
              zone.properties[key] =
                !zone.properties[key] &&
                (informations_urbanisme[key] ||
                  zone.properties.details_urbanisme)
                  ? zone.properties.details_urbanisme &&
                    zone.properties.details_urbanisme[keys_to_add[key]]
                    ? zone.properties.details_urbanisme[keys_to_add[key]].max
                    : informations_urbanisme[key]
                  : zone.properties[key]
            })
          })
          this.$store.commit('loading/SET_API_ZONES_URBANISME_LOADED', true)

          if (reload_map) {
            this.$refs.map_urbanisme.initMap()
          }
          this.$store.commit(
            'dossierpromoteur/setUrbanisme',
            informations_urbanisme,
          )
          return informations_urbanisme
        })
    },
    getCenterGeojson(geojson) {
      return this.$turf.pointOnFeature(this.$turf.buffer(geojson, -0.0003))
        .geometry.coordinates
    },
    getPrefixReglement(value) {
      return value.split('_').slice(0, 2).join('_')
    },
    addReglementFromEPCI(files, urbanisme, nomfic, pieces_ecrites) {
      let vm = this
      if (this.isExist(urbanisme.code_epci)) {
        for (let [key, value] of Object.entries(pieces_ecrites)) {
          if (
            vm.getPrefixReglement(nomfic) === vm.getPrefixReglement(key) &&
            key.split('_').length < 4
          ) {
            files.push({
              name: nomfic.split('_')[1],
              date: vm.getDatePiece(nomfic),
              value: value,
              isShown: true,
              editText: false,
              isUploaded: false,
            })
            delete pieces_ecrites[key]
          }
        }
      }
    },

    addDocsUrbanisme(urbanisme, pieces_ecrites) {
      let files = []
      let nomfic = this.getNestedObject(urbanisme, 'properties', 'nomfic')
      if (this.isExist(nomfic) && this.isExist(pieces_ecrites[nomfic])) {
        let reglement_urba = pieces_ecrites[nomfic]
        files.push({
          name: nomfic.split('_')[1],
          date: this.getDatePiece(nomfic),
          value: reglement_urba,
          isShown: true,
          editText: false,
          isUploaded: false,
        })
      }

      let temp_pieces_ecrites = this.copyObject(pieces_ecrites)
      this.addReglementFromEPCI(files, urbanisme, nomfic, temp_pieces_ecrites)

      for (let [key, value] of Object.entries(temp_pieces_ecrites)) {
        if (nomfic !== key || this.isExist(urbanisme.properties.code_epci)) {
          let key_doc = key.split('_')
          let name_doc = key_doc.slice(1, key_doc.length - 1).join(' ')
          files.push({
            name: name_doc,
            date: this.getDatePiece(key),
            value: value,
            isShown: true,
            editText: false,
            isUploaded: false,
          })
        } else if (nomfic !== key) {
          let key_doc = key.split('-')
          let name_doc = key_doc.slice(1, key_doc.length).join(' ')
          files.push({
            name: name_doc,
            date: this.getDateFormat(urbanisme.properties.datvalid),
            value: value,
            isShown: true,
            editText: false,
            isUploaded: false,
          })
        }
      }
      return files
    },

    getDateFormat(temp_date) {
      if (!this.isExist(temp_date)) return ''
      let year = temp_date.substring(0, 4)
      let mounth = temp_date.substring(4, 6)
      let day = temp_date.substring(6, 8)
      let date = day + '-' + mounth + '-' + year
      return date
    },
    getDatePiece(chaine) {
      var pieces = chaine.split('_')
      let temp_date = pieces[pieces.length - 1].split('.')[0]
      return this.getDateFormat(temp_date)
    },
    deleteArticle(index) {
      this.urbanisme.articles_plu.splice(index, 1)
    },
    addArticle() {
      this.urbanisme.articles_plu.push({
        title: null,
        text: null,
      })
    },
  },
}
